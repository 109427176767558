.square {
  min-width: 22px;
  height: 22px;
  background-color: #111;
  outline: 1px solid #222;
  display: inline-block;
}

.square-source {
  background-color: green;
}

.square-target {
  background-color: red;
}

.square-wall {
  background-color: rgb(12, 53, 71);
  animation-name: animateWall;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.square-visited {
  animation-name: animateVisit;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.square-path {
  background-color: rgb(255, 254, 106);
  animation-name: animatePath;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes animateWall {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateVisit {
  0% {
    transform: scale(0.6);
    background-color: rgba(17, 104, 217, 0.75);
  }

  100% {
    transform: scale(1);
    background-color: rgba(0, 190, 218, 0.75);
  }
}

@keyframes animatePath {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
